function Banner() {
  return (
    <div className="sec-banner bg0 p-t-80 p-b-50">
      <div className="container">
        <div className="row">
          <div className="col-sm-3">
            <div className="card">
              <div className="card-body">
                <h3 className="card-title d-flex justify-content-center"><b>Cash On Delivery</b></h3>
                <p className="card-text d-flex justify-content-center">Cash On Delivery Available.</p>
              </div>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="card">
              <div className="card-body">
                <h3 className="card-title d-flex justify-content-center"><b>Free Delivery</b></h3>
                <p className="card-text d-flex justify-content-center">On your all orders.</p>
              </div>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="card">
              <div className="card-body">
                <h3 className="card-title d-flex justify-content-center"><b>Return Policy</b></h3>
                <p className="card-text d-flex justify-content-center">Five days return policy.</p>
              </div>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="card">
              <div className="card-body">
                <h3 className="card-title d-flex justify-content-center"><b>24/7 Support</b></h3>
                <p className="card-text d-flex justify-content-center">Call & What's APP: 9988252428</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;